<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Group</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Group Name *"
                    v-model="group.groupName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Topic'"
                    @blur="$v.group.groupName.$touch()"
                    :is-valid="
                      $v.group.groupName.$dirty
                        ? !$v.group.groupName.$error
                        : null
                    "
                  >
                    <!-- <span v-if="mode.newMode||mode.editMode" style="color:red;">*</span>  -->
                  </CInput>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Group",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      groupId: "",
      group: {
        groupId: "0",
        groupName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    group: {
      groupName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.groupId = this.$route.params.groupId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.groupId = this.$route.params.groupId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "Groups" });
    },
    // saveSpeciality() {
    //   this.$v.group.$touch();
    //   if (this.$v.group.$invalid) {
    //     console.log("form is invalid", JSON.stringify(this.$v.group));
    //   }else{
    //  this.apiPostSecure(process.env.VUE_APP_API_HOST + "/emapp/web/save/group"
    //   ).then((post) => {
    //       this.$router.push({ name: "Groups" });
    //     });
    //   }

    // },

    save() {
      this.$v.group.$touch();
      if (this.$v.group.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.group));
      } else {
        fetch(process.env.VUE_APP_API_HOST + "/emapp/web/save/group", {
          method: "POST",
          headers: {
            "Content-type": "application/json",
          },
          body: JSON.stringify(this.group),
        });
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/group",
          this.group
        ).then((group) => {
          console.log(JSON.stringify(group));
          this.$router.push({ name: "Groups" });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/group/" +
          this.groupId
      )
        .then((response) => response.json())
        .then((data) => (this.group = data));
    },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
